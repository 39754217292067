/** Variables **/
$ProximaNova-Black: "Proxima Nova Bl", sans-serif;
$ProximaNova-Light: "Proxima Nova Lt", sans-serif;
$ProximaNova-Regular: "Proxima Nova Rg", sans-serif;
$black: #303030;

:root {
    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /* custom */
    --ion-color: #fff;
    --ion-background-color: transparent;
    --swiper-theme-color: #f5f5f5;
    --swiper-navigation-color: #f7f7f74d;
    --swiper-navigation-size: 6rem;
    --swiper-pagination-bullet-width: 1.2rem;
    --swiper-pagination-bullet-height: 1.2rem;
    --swiper-pagination-bullet-inactive-color: transparent;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 0.8rem;

    --ion-overlay-background-color: rgb(255, 255, 255);
    --ion-item-background: rgb(255, 255, 255);
}

ion-select::part(text) {
    max-width: 20px;
    background-image: url("/public/assets/img/lang.png");
    background-size: contain;
    color: transparent;
    background-position: center;
    background-repeat: no-repeat;
}

body {
    background: rgb(255, 32, 141);
    // background: linear-gradient(48deg, rgba(255, 32, 141, 1) 0%, rgba(255, 78, 83, 1) 57%, rgba(255, 122, 65, 1) 100%);
    background: linear-gradient(43deg, rgb(255, 32, 141) 0%, rgb(255, 78, 83) 57%, rgb(255, 122, 65) 100%);

    @media screen and (max-width: 768px) {
        background: linear-gradient(55deg, rgb(255 0 199) 0%, rgb(255, 78, 83) 67%, rgb(255, 122, 65) 100%);
    }

    /* total width */
    &::-webkit-scrollbar {
        display: none !important;
    }

    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
        display: none !important;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
        display: none !important;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
        display: none !important;
    }

    &.instructions-open {
        ion-tabs {
            .tab-selected {
                ion-title {
                    font-weight: normal;
                    border: none;
                }
            }

            ion-tab-button {
                overflow: visible;
                width: 110%;
            }

            ion-title {
                &.instruction {
                    // transform: scale(1.1);
                    width: 110%;
                    border: 4px solid #fff;
                    border-radius: 4rem;
                    padding: 0 1rem;
                    overflow: visible;
                    // &::after {
                    //     content: "";
                    //     position: absolute;
                    //     overflow: visible;
                    //     bottom: -2px;
                    //     left: calc(50% - (110% / 2));
                    //     // transform to the center of the screen
                    //     transform: translateX(-50%);
                    //     height: 0.1rem;
                    //     display: block;
                    //     background: #fff;
                    //     &:nth-child(1) {
                    //         right: 50%;
                    //     }
                    // }
                }
            }
        }
    }
}

html {
    scroll-behavior: smooth;
}

.no-ripple {
    --ripple-color: transparent;
}

.shadow {
    -webkit-box-shadow: 10px 10px 56px 0px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: 10px 10px 56px 0px rgba(0, 0, 0, 0.48);
    box-shadow: 10px 10px 56px 0px rgba(0, 0, 0, 0.48);
}

.pdf-viewer {
    display: none;
    transition: all 0.3s ease-in-out;

    &.open {
        overflow: scroll;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 999;
        text-align: center;
        background: rgba(0, 0, 0, 0.318);
        color: #fff;
        backdrop-filter: blur(8px);
    }

    .download-btn {
        width: auto;
        max-width: 120px;
        height: 48px;
        opacity: 0.7;
        transition: all 0.3s ease-in-out;

        &:hover {
            opacity: 1;
        }
    }

    .close-btn {
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        width: 52px;
        height: 52px;
        opacity: 0.7;
        transition: all 0.3s ease-in-out;

        &:hover {
            opacity: 1;
        }

        // @media screen and (max-width: 768px) {
        //     width: 32px;
        //     height: 32px;
        // }
    }

    .btn-holder {
        // position: absolute;
        position: sticky;
        height: 0;
        right: 1rem;
        z-index: 1;
        top: 0;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            right: 0;
        }

        >div {
            // margin-top: 2rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
        }
    }

    canvas {
        // object-fit: cover;
        width: 100% !important;
        height: auto !important;
    }

    .react-pdf__Page__textContent {
        display: none;
    }
}

.splash-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100vw;
    background: rgb(255, 32, 141);
    background: linear-gradient(93deg, rgb(255, 32, 141) 0%, rgb(255, 78, 83) 57%, rgb(255, 122, 65) 100%);
    top: 0;
    position: absolute;
    z-index: 999;
    left: 0;
    color: #fff;
    font-family: $ProximaNova-Black;
    font-style: italic;

    ion-grid {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .splash-inner {
        z-index: 1;
    }

    h1 {
        font-size: 2rem;

        @media screen and (max-width: 475px) {
            font-size: 1.5rem;
        }
    }

    .splash-bg,
    .splash-typo {
        display: block;
        width: auto;
        position: absolute;
        top: 0;
        height: 100vh;
        // opacity: 0.7;
        object-fit: cover;

        .marquee-container {
            padding: 2px;
        }
    }

    .splash-typo {
        overflow: hidden;

        h1 {
            font-family: $ProximaNova-Black;
            font-style: italic;
            font-size: 12rem;
            color: rgba(0, 0, 0, 0);
            -webkit-text-fill-color: rgba(0, 0, 0, 0);
            /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: black;
            text-transform: uppercase;
            margin: 0;
            padding: 0;
            width: 240%;
            line-height: 0.7;

            // &:nth-child(odd) {
            //     animation: moveLeft 90s linear infinite;
            // }
            // &:nth-child(even) {
            //     animation: moveRight 90s linear infinite;
            // }
            span {
                margin: 0 2rem;
                display: inline-block;
            }

            .text-blue {
                -webkit-text-stroke-color: #2faeff;
            }

            .text-green {
                -webkit-text-stroke-color: #15e4b5;
            }

            .text-yellow {
                -webkit-text-stroke-color: #ffc931;
            }

            .text-purple {
                -webkit-text-stroke-color: #a82bec;
            }
        }
    }

    .logo {
        width: 80%;
        max-width: 300px;
        margin: 0 auto;
    }

    .desc {
        max-width: 400px;
        margin-top: 1rem;
    }
}

ion-content {

    ion-slides,
    .swiper {
        height: auto;
    }

    .swiper-pagination {
        bottom: unset !important;
        top: 2rem !important;
        z-index: unset;
    }

    .swiper-pagination-bullet {
        border: 1px solid #fff;
    }

    .swiper-wrapper {
        // justify-content: center;
        align-items: flex-start !important;
        // z-index: unset;
    }

    .swiper-slide {
        height: 100%;
        font-family: $ProximaNova-Regular;
        font-size: 2rem;
        text-align: center;
        color: #fff;
        margin-top: 8rem;
        transition: all 0.2s;
        transform: scale(0.9) !important;
    }

    .swiper-slide-active {
        transform: scale(1) !important;
    }

    .swiper-button-next,
    .swiper-button-prev {
        z-index: 10;

        @media screen and (max-width: 768px) {
            display: none;
        }

        &.swiper-button-disabled {
            display: none;
        }
    }
}

.sticky-download-btn {
    transform: rotate(90deg);
    position: absolute;
    z-index: 101;
    left: -4.5rem;
    top: 80%;
    color: #fff;
    background: #231f20;
    display: block;
    padding: 0.5rem 0.9rem;
    border-radius: 2rem;
    text-transform: uppercase;
    font-size: 0.8rem;
    transition: 0.4s all;
    cursor: pointer;

    a {
        text-decoration: none;
        color: #fff;
        background: transparent;
    }

    &:hover {
        left: -4.3rem;
    }

    &::after {
        content: "";
        height: 1rem;
        display: block;
    }
}

.instructions-background-blur {
    position: absolute;
    width: 100%;
    z-index: 90;
    height: 100%;
    backdrop-filter: blur(8px);

    @supports (-moz-appearance: none) {
        background: rgba(0, 0, 0, 0.146);
    }
}

.instructions {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    color: #fff;
    font-family: $ProximaNova-Black;
    font-style: italic;
    text-align: center;
    z-index: 105;
    overflow: scroll;
    height: 100%;

    .instructions-line {
        margin-left: auto;
        margin-right: auto;
        background: #fff;
        width: 0.2rem;
        height: 4rem;
        border-radius: 4rem;
    }

    h1 {
        font-size: 1.8rem;

        @media screen and (max-width: 475px) {
            font-size: 1.4rem;
        }

        // &:nth-child(2) {
        //     @media (max-height: 700px) {
        //         // visibility: hidden;
        //         // display: none;
        //     }
        // }
    }

    ion-img {
        animation: rotate 2s linear infinite;

        @media (max-height: 700px) {
            max-width: 4rem !important;
            margin-top: -2rem !important;
        }

        @keyframes rotate {
            0% {
                transform: rotate(0deg);
            }

            40% {
                transform: rotate(10deg);
            }

            60% {
                transform: rotate(20deg);
            }

            80% {
                transform: rotate(30deg);
            }

            100% {
                transform: rotate(0deg);
            }
        }
    }

    .instructions-img {
        max-width: 12rem;
        margin: 0 auto;
        clear: both;
        width: 90%;
        margin-top: 5.3rem;

        @media (max-width: 512px) {
            max-width: 40%;
            top: 6rem;
        }

        @media (max-height: 700px) {
            max-width: 10% !important;
            margin-top: 4rem !important;
            visibility: hidden;
        }

        // loop animation to rotate left and right
    }
}

.white-btn {
    @extend .shadow;
    font-family: $ProximaNova-Black;
    font-style: italic;
    background: #fff;
    padding: 0.5rem 1rem;
    display: inline-block;
    max-width: 12rem;
    margin: 2rem auto;
    font-size: 1.8rem;
    border-radius: 4rem;
    color: #ff00c7;
    text-decoration: none;

    @supports (-moz-appearance: none) {
        margin-top: 3rem;
    }
}

.container {
    color: #fff;
    text-align: center;
    position: absolute;
    // display: none;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    strong {
        font-size: 20px;
        line-height: 26px;
    }

    p {
        font-size: 16px;
        line-height: 22px;
        color: #8c8c8c;
        margin: 0;
    }

    a {
        text-decoration: none;
    }
}

.header {
    .header-logo {
        max-width: 10rem;
        cursor: pointer;
        margin: 0 auto;
    }

    .header-nav {
        top: 5rem;
    }

    .tab-selected {
        ion-title {
            font-family: $ProximaNova-Regular;
            border-bottom: 3px solid;
            font-weight: 600;
        }
    }

    .navbar-chevron-left {
        display: none;
        cursor: pointer;
        width: 3rem;
        height: 2rem;
        position: absolute;
        left: 0;
        background-image: linear-gradient(to left, rgb(255 66 101 / 63%), rgb(255 70 95 / 94%) 90%);

        @media screen and (min-width: 768px) {
            display: none;
        }
    }

    .navbar-chevron-right {
        // @extend .shadow;
        cursor: pointer;
        width: 3rem;
        height: 2rem;
        position: absolute;
        right: 0;
        background-image: linear-gradient(to right, rgb(255 112 70 / 66%), rgb(254 111 70) 90%);
        border-radius: 0.4rem;

        @media screen and (min-width: 768px) {
            display: none;
        }
    }

    ion-tab-bar {
        @extend .shadow;
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-bottom: none;
        overflow-x: scroll;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;

        /* Firefox */
        &::-webkit-scrollbar {
            display: none;
            /* Safari and Chrome */
        }

        @media (max-width: 768px) {
            justify-content: flex-start;
        }

        ion-tab-button {
            height: 2.5rem;
            margin: 0 0.5rem;
            width: 100%;
            min-width: 200px; // for adjusting width in ion-tab-button, use min-width

            ion-title {
                // transition: all 0.1s;
                font-family: $ProximaNova-Light;
                font-size: 1.1rem;
                text-transform: uppercase;
                font-weight: 400;
                padding: 0;
            }
        }
    }
}

.d-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.tabs-inner {
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s ease-in-out;
}

.home {
    .container {
        text-align: center;

        h1 {
            font-family: $ProximaNova-Black;
            font-style: italic;
            font-size: 3.5rem;
            color: #fff;
            max-width: 700px;
            padding: 0 1rem;
            margin: 5rem auto 1rem;

            @media screen and (max-width: 768px) {
                font-size: 3.2rem;
            }
        }

        p {
            font-family: $ProximaNova-Regular;
            font-size: 1.4rem;
            color: #fff;
            max-width: 768px;
            padding: 0 1rem;
            margin: 1.5rem auto;

            @media screen and (max-width: 768px) {
                font-size: 1.2rem;
            }

            @media screen and (max-width: 512px) {
                padding: 0 2rem;
            }
        }
    }
}

.container-inner {
    width: 85%;

    @media screen and (max-width: 768px) {
        width: 90%;
    }

    margin: -3rem auto 2rem;
    overflow: hidden;
    box-shadow: 2px 7px 20px 6px rgb(0 0 0 / 29%);
    border-radius: 1rem;

    .container-title,
    h1 {
        font-family: $ProximaNova-Black;
        font-size: 3.5rem;
        color: #fff;
        background: #28e8ff;
        display: block;
        width: 100%;
        text-align: left;
        // border-radius: 1rem 1rem 0 0;
        padding: 1.2rem 2rem;
        border-bottom: 4px solid #303030;
        margin-bottom: 0;
        margin-top: 0;

        @media screen and (max-width: 768px) {
            padding: 1.2rem 1rem 0.8rem;
            line-height: 0.8;
            font-size: 3rem;
        }
    }

    .container-content {
        min-height: 200px;
        width: 100%;
        position: relative;
        // min-height: 400px;
        // border-radius: 0 0 1rem 1rem;
        display: flex;
        flex-direction: column;
        padding: 1.2rem 2rem;

        @media screen and (max-width: 768px) {
            padding: 0.8rem 1rem 2rem;
        }

        p {
            font-size: 1.4rem;
            text-align: left;
        }
    }

    &.design-one {
        ion-img {
            width: 100%;
            // height: 300px;
            height: 200px;
            object-fit: cover;
            // @media screen and (max-width: 768px) {
            //     height: 400px;
            // }
        }

        .container-content {
            >div.flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
            }
        }

        embed {
            // object-fit: cover;
            overflow: scroll;
            aspect-ratio: auto;
            width: 100%;
            height: 450px;
            background: #fff;

            @media screen and (max-width: 768px) {
                height: 500px;

                // ios only
                @supports (-webkit-touch-callout: none) {
                    height: 100%;
                }
            }
        }

        a,
        button {
            font-size: 1.8rem;
            font-weight: bold;
            text-decoration: underline;
            padding: 0.5rem 1rem;

            @media screen and (max-width: 768px) {
                margin-top: 1rem;
            }
        }

        p {
            @media screen and (max-width: 768px) {
                padding-top: 0rem;
                margin-top: 0.4rem;
                margin-bottom: 1rem;
            }
        }

        .close-btn {
            position: absolute;
            width: 48px;
            height: 48px;
            right: 1rem;
            z-index: 1;
            top: 0;
            cursor: pointer;

            @media screen and (max-width: 768px) {
                width: 32px;
                height: 32px;
                right: 0;
            }
        }
    }

    &.safety-syllabus-consent {
        .container-title {
            color: #fff;
            background: black;
        }

        .container-content {
            color: #fff;
            background: black;
            padding: 1rem 1.4rem;
        }

        a {
            color: #fff;
        }
    }

    &.dating-101-online,
    &.safety-syllabus-safe-space {
        h1.container-title {
            padding: 1.2rem 2.4rem;
        }
    }

    &.design-video {
        .container-title {
            color: #fff;
            background: rgb(11 11 11 / 50%);
            border-bottom: none;
        }

        .container-content {
            color: #fff;
            background: rgb(11 11 11 / 50%);
            padding: 0rem 1.4rem 3rem;
        }

        .video-container {
            .thumbnail {
                position: relative;

                &::after {
                    cursor: pointer;
                    content: "";
                    background: url("/public/assets/img/play-btn.png") no-repeat;
                    position: absolute;
                    left: 40%;
                    width: 80px;
                    height: 80px;
                    top: 35%;
                    background-size: contain !important;
                }
            }

            a {
                color: #fff;
            }
        }

        .video-container,
        .img-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            text-align: left;

            >div {
                flex: 1;
                padding: 0.6rem;

                @media screen and (max-width: 768px) {
                    padding: 0.6rem 0;
                }
            }

            @media screen and (max-width: 768px) {
                flex-direction: column;
            }

            .thumbnail {
                width: 100%;
                position: relative;

                // @media screen and (min-width: 768px) {
                //     height: 18rem;
                // }
                ion-img {
                    height: 100%;
                    object-fit: contain;
                }
            }

            .title,
            h3 {
                font-family: $ProximaNova-Black;
                font-size: 2rem;
                margin: 0;

                @media screen and (max-width: 768px) {
                    font-size: 1.6rem;
                }
            }

            p {
                font-family: $ProximaNova-Light;
                font-size: 1.4rem;
                margin: 0;
                margin-top: 1rem;
            }

            a {
                font-family: $ProximaNova-Black;
                text-decoration: underline;
                text-transform: uppercase;
                font-size: 1.3rem;

                // margin-top: 7rem;
                @media screen and (max-width: 768px) {
                    margin-top: 1rem;
                }
            }

            .description-outer {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @media screen and (min-width: 768px) {
                    height: 23vw;
                }
            }
        }
    }

    &.safety-syllabus-safe-space {
        .container-title {
            color: #fff;
            background: #a200ff;
        }

        .container-content {
            color: $black;
            background: #fff;
            padding: 1rem 1.4rem 1rem;
        }

        .img-container {
            a {
                color: $black;
            }
        }
    }

    &.swipe-stories {
        color: #fff;
        background: rgb(11 11 11 / 50%);

        .container-content {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
            width: 100%;
            padding: 1.4rem 1rem;

            @media screen and (max-width: 768px) {
                grid-template-columns: repeat(2, 1fr);
            }

            >.story {
                // max-width: 32%;
                position: relative;
                box-sizing: border-box;

                &::after {
                    cursor: pointer;
                    content: "";
                    background: url("/public/assets/img/play-btn.png") no-repeat;
                    position: absolute;
                    left: 36%;
                    width: 80px;
                    height: 80px;
                    top: 36%;
                    background-size: contain !important;

                    @media screen and (max-width: 768px) {
                        width: 56px;
                        height: 56px;
                    }
                }
            }
        }
    }

    &.dating-101-online {
        .container-title {
            color: #fff;
            background: #ff7531;
        }

        .container-content {
            color: $black;
            background: #fff;
            padding: 1rem 1.4rem;
        }

        a {
            color: $black;
        }
    }
}

.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s ease-in-out;
}

.hide-desktop {
    @media screen and (min-width: 768px) {
        display: none;
    }
}

.hide-mobile {
    @media screen and (max-width: 768px) {
        display: none;
    }
}

@keyframes moveLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes moveRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

@font-face {
    font-family: 'Recife';
    src: local('Recife Display Black'), local('/public/assets/fonts/Recife-Display-Black'),
        url('/public/assets/fonts/RecifeDisplay-Black.woff2') format('woff2'),
        url('/public/assets/fonts/RecifeDisplay-Black.woff') format('woff'),
        url('/public/assets/fonts/RecifeDisplay-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}